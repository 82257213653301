import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Image from "react-bootstrap/Image";
import "../css/header.css";
import logo from "../images/Homebuyer Connect_H Logo.png";

export default function Header(props) {
  
  return (
    <>
      <Navbar
        collapseOnSelect
        className="px-2 px-md-3 px-lg-5 fixed-top"
        bg="light"
        expand="lg"
      >
        <Navbar.Brand as={Link} to="/">
          <Image fluid src={logo} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="justify-content-end" id="basic-navbar-nav">
          <Nav>
              <Nav.Link
               className="clrd-btn btn text-white d-md-block d-lg-none text-center mx-3 mb-3"
               onClick={() => {
                props.setModalShow(true);
              }}
              eventKey="1"
            >
               Get Started
            </Nav.Link> 

            <NavDropdown title="About" id="about-nav-dropdown">
              <NavDropdown.Item as={Link} className="navLink" to="/about" eventKey="2">
                About Us
              </NavDropdown.Item>
              <NavDropdown.Item
                className="navLink"
                href="https://www.swmc.com/why-sunwest"
                target="_blank"
                eventKey="3"
              >
                Testimonials
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Resources" id="resources-nav-dropdown">
              <NavDropdown.Item
                as={Link}
                className="navLink"
                to="/loan-programs"
                eventKey="4"
              >
                Loan Programs
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} className="navLink" to="/calculators" eventKey="5">
                Mortgage Calculators
              </NavDropdown.Item>
              <NavDropdown.Item
                className="navLink"
                href="https://www.swmc.com/blog/"
                rel="noopener noreferrer"
                target="_blank"
                eventKey="6"
              >
                Blog
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} className="navLink" to="/contact" eventKey="7">
              Contact
            </Nav.Link>
            {/* <Nav.Link
            > */}
              <Button variant="primary" eventKey="8" className="clrd-btn btn text-white d-none d-sm-none d-md-none d-lg-block"
                    onClick={() => {
                      props.setModalShow(true);
                    }}>
               Get Started
              </Button>
            {/* </Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
     
    </>
  );
}
