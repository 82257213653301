import React, { useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import CheckMark from "../../images/check.svg";
function Expectations() {
  const [expectations] = useState([
    "Competitive Rates and Terms",
    "Affordable Down Payments",
    "Fast Turn-Times",
    "No Hidden Lender Fees",
    "A TRU Approval so you can search confidently",
    "Dedicated and experienced loan officers",
    "Innovative technology that improves your experience",
    "In-House Servicing on the majority of loans",
  ]);
  return (
    <React.Fragment>
      <Container
        className="text-start px-0 pt-4"
      >
        <Row className="g-0">
          <Col className="px-0" lg={12}>
            <h3 className="fw-bold text-dark-gray">
              What You can
              <span className="luck-md baby-blue font-weight-normal pl-2">
                Expect
              </span>
            </h3>
          </Col>
        </Row>
        <Row className="my-4 pb-3">
          <Col sm={12} lg={6} style={{ color: "#083F88" }}>
            <ul className="expect-list">
              {expectations.map((item, i) => {
                return <li key={i}>{item}</li>;
              })}
            </ul>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <p>
              Your Market Manager is available{" "}
              <span className="fw-bold" style={{ color: "#1C2253" }}>
                when you need them
              </span>{" "}
              by cell, email and text – 7 days a week even after your loan has
              closed. And they are local, so if you need a painter, landscaper,
              or financial advisor – your Market Manager can connect you. At
              Homebuyer Connect, we are here to connect you to what matters most
              on your homebuying journey – no matter where life takes you!
            </p>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}
export default Expectations;
