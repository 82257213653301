import React,{useEffect} from 'react';
import TopBar from "../components/calculators/topbar";
import MainContent from "../components/calculators/mainContent";
import CalculatorList from "../components/calculators/calclist";
import {Helmet} from "react-helmet";
import ReactGA from 'react-ga';
// import TagManager from 'react-gtm-module';

// const tagManagerArgs = {
//     gtmId: 'GTM-NJX2WC6',
//     dataLayer: {
//       page: 'calculators'
//   },
//   dataLayerName: 'PageDataLayer'
//   }

function Calculators(props) {

    useEffect(() => {
        ReactGA.initialize('UA-187484893-1');
        ReactGA.pageview(window.location.pathname + window.location.search);

        // TagManager.initialize(tagManagerArgs)
        // TagManager.dataLayer(tagManagerArgs)
    }, []);

    return (
        <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Mortgage Calculators | Homebuyer Connect</title>
                <link rel="canonical" href="https://homebuyerconnect.com/calculators" />
            </Helmet>
            <TopBar setModalShow={props.setModalShow}></TopBar>
            <MainContent setModalShow={props.setModalShow}></MainContent>
            <CalculatorList setModalShow={props.setModalShow}></CalculatorList>
        </React.Fragment>
    );
}
export default Calculators;