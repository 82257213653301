import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import "../../css/custom.css"
function TopBar(props) {
    return (
        <>
            <Container fluid className="calculators-hero px-0">
                <div className="triangular h-100">
                    <Container className="h-100 w-100 d-flex align-items-center">
                        <Row className="hero-contents w-100">
                            <Col className="text-center mt-5 pt-5 hero-text mx-auto" lg={8}>
                                <span className="lucky light-blue">Mortgage</span>
                                <p style={{ fontSize: "45px" }} className="d-inline h2"> Calculators</p><br />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Container>
        </>
    );
}
export default TopBar;