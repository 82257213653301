import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Helmet } from "react-helmet";

export default function Sitemap(props) {
  
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sitemap | Homebuyer Connect</title>
        <link rel="canonical" href="https://homebuyerconnect.com/calculators" />
      </Helmet>
      <Container fluid className="sitemap-hero px-0">
        <div className="triangular h-100">
          <Container className="h-100 d-flex align-items-center">
            <Row className="hero-contents w-100">
              <Col className="text-center mt-md-0 pt-md-0 pt-lg-5" lg={12}>
                <h1 className="text-white">Sitemap</h1>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
      <Container className="my-4 py-4 sitemapWrap">
        <Row className="justify-content-center">
          <Col md={4}>
            <h4>
              <Link to="/">Home</Link>
            </h4>
            <h4>
              <Link to="/about">About Us</Link>
            </h4>
            <h4>
              <a
                href="https://www.swmc.com/why-sunwest"
                target="_blank"
                rel="noopener noreferrer"
              >
                Testimonials
              </a>
            </h4>
          </Col>
          <Col md={4}>
            <h4>
              <Link to="/loan-programs">Loan&nbsp;Programs</Link>
            </h4>
            <h4>
              <Link to="/calculators">Mortgage&nbsp;Calculators</Link>
            </h4>
            <h4>
              <a
                href="https://www.swmc.com/blog/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Blog
              </a>
            </h4>
          </Col>
          <Col md={4}>
            <h4>
              <Link to="/contact">Contact Us</Link>
            </h4>
            <h4>
               <Button variant="primary" className="dblue clrd-btn text-white ms-0"  onClick={() => {
                      props.setModalShow(true);
                    }}>
               Get Started
              </Button>
            </h4>
          </Col>
        </Row>
      </Container>
    </>
  );
}
