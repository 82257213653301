/* eslint-disable react/jsx-no-target-blank */
import React, {
  Fragment,
  useState,
  useEffect
} from "react";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { Player } from "video-react";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
// import TagManager from 'react-gtm-module';

import loanCouple from "../images/loan-couple.png";
import convIcon from "../images/conventional-icon.png";
import dpaIcon from "../images/down-payment-icon.png";
import fhaIcon from "../images/fha-icon.png";
import jumboIcon from "../images/jumbo-icon.png";
import refiIcon from "../images/refinance-icon.png";
import renoIcon from "../images/renovation-icon.png";
import usdaIcon from "../images/usda-icon.png";
import vaIcon from "../images/va-icon.png";
import reverseIcon from "../images/reverse-icon.png";
import triangles from "../images/triangles.png";
import truVideo from "../videos/1362 TRU Approval - Homebuyer Connect.mp4";
import "video-react/dist/video-react.css";
import "../css/loanprograms.css";
import poster from "../images/thumbnail-video.jpg";

// const tagManagerArgs = {
//     gtmId: 'GTM-NJX2WC6',
//     dataLayer: {
//       page: 'loan programs'
//   },
//   dataLayerName: 'PageDataLayer'
//   }

export default function LoanPrograms(props) {
  
  const [showConventional, setConventional] = useState(false);
  const [showDPA, setDPA] = useState(false);
  const [showFha, setFha] = useState(false);
  const [showJumbo, setJumbo] = useState(false);
  const [showRefi, setRefi] = useState(false);
  const [showReno, setReno] = useState(false);
  const [showUsda, setUsda] = useState(false);
  const [showVa, setVa] = useState(false);
  const [showRm, setRm] = useState(false);

  useEffect(() => {
    ReactGA.initialize("UA-187484893-1");
    ReactGA.pageview(window.location.pathname + window.location.search);

    // TagManager.initialize(tagManagerArgs)
    // TagManager.dataLayer(tagManagerArgs)
  }, []);

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Loan Programs | Homebuyer Connect</title>
        <link
          rel="canonical"
          href="https://homebuyerconnect.com/loan-programs"
        />
      </Helmet>
      <Container fluid className="loan-hero px-0">
        <div className="triangular h-100">
          <Container className="h-100 d-flex align-items-center">
            <Row className="hero-contents w-100">
              <Col className="text-center mt-md-0 pt-md-0 pt-lg-5" lg={12}>
                <h1 className="text-white">
                  <span className="lucky baby-blue">Loan</span> Programs
                </h1>
                <div className="pt-3">
                   <Button variant="primary" className="dblue clrd-btn text-white ms-0 px-5"    onClick={() => {
                      props.setModalShow(true);
                    }}>
               Get Started
              </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>

      <Container className="pt-5">
        <Row className="d-flex justify-content-between">
          <Col sm={12} lg={6}>
            <h2 className="page-title mr-5 mb-3 rect-title d-inline-block">
              <span className="light-blue-rectangle px-1">Connecting</span> You
              to the Loan Program Perfect for You
            </h2>
            <p className="mt-4">
              Homebuyer Connect offers our customers not only competitive
              mortgage rates, but also a wide selection of mortgage products. We
              believe that everyone deserves the American dream and have loan
              programs available for credit scores as low as 500. Your local
              Market Manager will help you decide which one is right for you and
              your family. No matter which loan program you choose, getting a
              TRU Approval with Homebuyer Connect is an important step on your
              homebuying journey and will help you search for your dream home
              with confidence.
            </p>
          </Col>
          <Col lg={5}>
            <Image src={loanCouple} className="img-fluid" />
          </Col>
        </Row>
      </Container>
      <Container fluid className="py-3 py-sm-5 my-3 my-sm-5 px-0">
        <Row className="d-flex justify-content-center w-100 mr-0 mt-5 pt-5">
          <Col lg={12} className="pr-0">
            <h2 className="page-title rect-title text-center">
              The{" "}
              <span
                style={{ position: "relative" }}
                className="light-blue-rectangle px-1"
              >
                TRU
              </span>{" "}
              Approval Process
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center tru-row mt-4 mt-sm-5">
          <Col md={12} lg={6} className="pt-4 px-5 video-col d-block">
            <Player className="shadow" poster={poster}>
              <source src={truVideo} />
            </Player>

            <p className="pt-5 px-2 px-sm-4">
              A TRU Approval is more than just your standard pre-approval from
              another lender. A TRU Approval means you are Thoroughly Reviewed
              and Underwritten for your loan, giving you a big advantage when it
              comes to putting in an offer.
            </p>
            <p className="px-2 px-sm-4 pb-5">
              You&#39;ll know exactly how much home you can afford and an
              estimate of what your monthly mortgage payment will be. We&#39;ll
              also be able to address potential credit issues or other hiccups
              early in the process so once you find your dream home, we can
              close and get you moved in fast.
            </p>

            <h4 className="dark-gray text-center fw-bold pb-3">
              Get <span className="luck-md">TRU</span> Approved Today
            </h4>
            <div className="d-flex mb-n3 justify-content-center">
               <Button variant="primary" className="dblue clrd-btn text-white px-5 ms-0" onClick={() => {
                      props.setModalShow(true);
                    }}>
               Get Started
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="d-flex justify-content-center m-auto text-center text-md-left">
          <Col
            sm={12}
            md={10}
            lg={8}
            xl={6}
            className="ml-1 ps-1 ms-sm-2 ps-sm-2 ms-md-5 ps-md-5"
          >
            <h2 className="page-title mb-3 rect-title d-inline-block">
              <span className="light-blue-rectangle px-1">Understanding</span>{" "}
              the Primary Loan Types
            </h2>
          </Col>
          <Col lg={12} className="text-center pt-3">
            <p className="md-blue-title">
              Below are some of the programs offered here at Homebuyer Connect:
            </p>
          </Col>
        </Row>
        <Row className="pt-5 justify-content-center">
          <div
            className={`loan-boxes ${showConventional ? "bg-blue" : ""}`}
            onClick={() => setConventional(!showConventional)}
          >
            <div className={`${showConventional ? "d-none" : "text-center"}`}>
              <Image src={convIcon} className="img-fluid" />
              <div className="pt-4">
                <h5>Conventional Loans</h5>
              </div>
            </div>
            <div className={`${showConventional ? "d-block" : "d-none"}`}>
              <p className="text-start px-3 mb-0" style={{ color: "#F4F6FAF5" }}>
                Conventional Loans
              </p>
              <p
                className="px-3 pt-2 text-white mb-0"
                style={{ fontSize: "14px" }}
              >
                The most popular loan type as it typically offers low interest
                rates, fast processing and more flexibility. A conventional
                mortgage or conventional loan is any type of homebuyer&#39;s
                loan that is not offered or secured by a government entity, but
                rather available through or guaranteed by a private lender or
                the two government-sponsored enterprises Fannie Mae and Freddie
                Mac.
              </p>
            </div>
          </div>
          <div
            className={`loan-boxes ${showDPA ? "bg-blue" : ""}`}
            onClick={() => setDPA(!showDPA)}
          >
            <div className={`${showDPA ? "d-none" : "text-center"}`}>
              <Image src={dpaIcon} className="img-fluid" />
              <div className="pt-4">
                <h5>Down Payment Assistance</h5>
              </div>
            </div>
            <div className={`${showDPA ? "d-block" : "d-none"}`}>
              <p className="text-start px-3 mb-0" style={{ color: "#F4F6FAF5" }}>
                Down Payment Assistance
              </p>
              <p
                className="px-3 pt-2 text-white mb-0"
                style={{ fontSize: "14px" }}
              >
                Coming up with a down payment to purchase a new home can feel
                like a giant obstacle. Down payment assistance programs can
                help. They typically vary from state to state, ask your local
                Market Manager about options in your area.
              </p>
            </div>
          </div>
          <div
            className={`loan-boxes ${showFha ? "bg-blue" : ""}`}
            onClick={() => setFha(!showFha)}
          >
            <div className={`${showFha ? "d-none" : "text-center"}`}>
              <Image src={fhaIcon} className="img-fluid" />
              <div className="pt-4">
                <h5>FHA Loans</h5>
              </div>
            </div>
            <div className={`${showFha ? "d-block" : "d-none"}`}>
              <p className="text-start px-3 mb-0" style={{ color: "#F4F6FAF5" }}>
                FHA Loans
              </p>
              <p
                className="px-3 pt-2 text-white mb-0"
                style={{ fontSize: "14px" }}
              >
                This is the most common option for a first-time homebuyer. The
                FHA loan is a federally backed loan that allows the buyer to put
                down a smaller down payment, typically has a competitive
                interest rate, and is more lenient when reviewing your credit
                history.
              </p>
            </div>
          </div>
        </Row>
        <Row className="justify-content-center">
          <div
            className={`loan-boxes ${showJumbo ? "bg-blue" : ""}`}
            onClick={() => setJumbo(!showJumbo)}
          >
            <div className={`${showJumbo ? "d-none" : "text-center"}`}>
              <Image src={jumboIcon} className="img-fluid" />
              <div className="pt-4">
                <h5>Jumbo Loans</h5>
              </div>
            </div>
            <div className={`${showJumbo ? "d-block" : "d-none"}`}>
              <p className="text-start px-3 mb-0" style={{ color: "#F4F6FAF5" }}>
                Jumbo Loans
              </p>
              <p
                className="px-3 pt-2 text-white mb-0"
                style={{ fontSize: "14px" }}
              >
                When you purchase a new home, there are typically limits on the
                amount of money you can borrow. With stricter qualifications, a
                Jumbo loan, allows you to borrow more money than a conventional
                loan would so you can purchase a more expensive property.
              </p>
            </div>
          </div>
          <div
            className={`loan-boxes ${showRefi ? "bg-blue" : ""}`}
            onClick={() => setRefi(!showRefi)}
          >
            <div className={`${showRefi ? "d-none" : "text-center"}`}>
              <Image src={refiIcon} className="img-fluid" />
              <div className="pt-4">
                <h5>Refinancing</h5>
              </div>
            </div>
            <div className={`${showRefi ? "d-block" : "d-none"}`}>
              <p className="text-start px-3 mb-0" style={{ color: "#F4F6FAF5" }}>
                Refinancing
              </p>
              <p
                className="px-3 pt-2 text-white mb-0"
                style={{ fontSize: "14px" }}
              >
                With interest rates being very low, now is a great time to
                consider a refinance. Benefits of refinancing include obtaining
                a lower interest rate, changing your loan term (paying off your
                mortgage sooner, reducing payments by extending, or switching
                from an ARM to a Fixed), consolidating debt, or taking cash out
                for home improvements.
              </p>
            </div>
          </div>
          <div
            className={`loan-boxes ${showReno ? "bg-blue" : ""}`}
            onClick={() => setReno(!showReno)}
          >
            <div className={`${showReno ? "d-none" : "text-center"}`}>
              <Image src={renoIcon} className="img-fluid" />
              <div className="pt-4">
                <h5>Renovation Loans</h5>
              </div>
            </div>
            <div className={`${showReno ? "d-block" : "d-none"}`}>
              <p className="text-start px-3 mb-0" style={{ color: "#F4F6FAF5" }}>
                Renovation Loans
              </p>
              <p
                className="px-3 pt-2 text-white mb-0"
                style={{ fontSize: "14px" }}
              >
                A loan option that allows you to add extra money for remodeling
                or improvements to an initial home purchase mortgage or a
                mortgage refinancing.
              </p>
            </div>
          </div>
        </Row>
        <Row className="justify-content-center">
          <div
            className={`loan-boxes ${showRm ? "bg-blue" : ""}`}
            onClick={() => setRm(!showRm)}
          >
            <div className={`${showRm ? "d-none" : "text-center"}`}>
              <Image src={reverseIcon} className="img-fluid" />
              <div className="pt-4">
                <h5>Reverse Mortgages</h5>
              </div>
            </div>
            <div className={`${showRm ? "d-block" : "d-none"}`}>
              <p className="text-start px-3 mb-0" style={{ color: "#F4F6FAF5" }}>
                Reverse Mortgages
              </p>
              <p
                className="px-3 pt-2 text-white mb-0"
                style={{ fontSize: "14px" }}
              >
                Available to homeowners 62 years or older, convert part of the
                equity in your home into cash. Each month instead of making a
                payment to us, we’ll send you money that can be used for
                anything you want including living expenses, health care, and
                travel. You maintain ownership of your home and are still
                responsible for paying property tax and homeowner’s insurance.
              </p>
            </div>
          </div>
          <div
            className={`loan-boxes ${showUsda ? "bg-blue" : ""}`}
            onClick={() => setUsda(!showUsda)}
          >
            <div className={`${showUsda ? "d-none" : "text-center"}`}>
              <Image src={usdaIcon} className="img-fluid" />
              <div className="pt-4">
                <h5>USDA Loans</h5>
              </div>
            </div>
            <div className={`${showUsda ? "d-block" : "d-none"}`}>
              <p className="text-start px-3 mb-0" style={{ color: "#F4F6FAF5" }}>
                USDA Loans
              </p>
              <p
                className="px-3 pt-2 text-white mb-0"
                style={{ fontSize: "14px" }}
              >
                For prospective homebuyers who are looking for property in a
                rural area, a USDA loan offers the option to purchase a home
                with no down payment. These government loans are meant to help
                revitalize rural landscapes by increasing home ownership,
                encouraging development, and assisting with rehabilitation
                projects.
              </p>
            </div>
          </div>
          <div
            className={`loan-boxes ${showVa ? "bg-blue" : ""}`}
            onClick={() => setVa(!showVa)}
          >
            <div className={`${showVa ? "d-none" : "text-center"}`}>
              <Image src={vaIcon} className="img-fluid" />
              <div className="pt-4">
                <h5>VA Loans</h5>
              </div>
            </div>
            <div className={`${showVa ? "d-block" : "d-none"}`}>
              <p className="text-start px-3 mb-0" style={{ color: "#F4F6FAF5" }}>
                VA Loans
              </p>
              <p
                className="px-3 pt-2 text-white mb-0"
                style={{ fontSize: "14px" }}
              >
                A VA loan is available to eligible military veterans and their
                surviving spouses. It allows you to purchase a home with 0%
                down, minimal closing costs, and fewer restrictions than a
                traditional loan.
              </p>
            </div>
          </div>
        </Row>
        <Row className="pt-5 mt-4">
          <Col className="text-center">
            <Image
              className="triangleImage"
              src={triangles}
              style={{ transform: "scaley(-1) scalex(-1)" }}
            />
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row className="loan-subfooter" style={{ backgroundSize: "cover" }}>
          <Col>
          <Container>
            <Row className="align-items-center py-4 py-sm-1 py-md-3">
              <Col sm={12} lg={7} className="pt-sm-0 pb-sm-1 py-lg-4">
                <h2 className="big-white-title">
                  The{" "}
                  <span
                    style={{
                      position: "relative",
                      background:
                        "linear-gradient(to top, #30c2f3d1 45%, transparent 45%)",
                    }}
                    className="light-blue-rectangle px-1"
                  >
                    mortgage
                  </span>{" "}
                  process shouldn&#39;t be stressful!
                </h2>
                <p className="baby-blue mb-sm-0">
                  Connect with your local Market Manager today, and they can
                  help you choose the best loan program for you and your family!
                </p>
              </Col>
              <Col style={{ textAlign: "end" }}>
                 <Button variant="primary" className="btn-orange btn text-white my-auto px-5 py-2"    onClick={() => {
                      props.setModalShow(true);
                    }}>
               Get Started
              </Button>
              </Col>
            </Row>
          </Container>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
