/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, } from "react";
import { Col, Row, Container, Image } from "react-bootstrap";
import triangles from "../../images/triangles.png";
import IframeResizer from "iframe-resizer-react";


// const iframeResizerOptions = { checkOrigin: false };

function CalcList(props) {
  const [Active, setActive] = useState(1);

  const [calc] = useState([
    {
      id: 1,
      title: "Fixed-Rate Mortgage Calculator",
      details:
        "Find out what your monthly payments will be for this common mortgage type based on various down payment amounts and terms.",
      icon: "fixed-icon.svg",
      link: "https://swmc-landingpages.s3.amazonaws.com/mortgage-calculators/fixed-rate-calculator.html",
    },
    {
      id: 2,
      title: "Refinance Calculator",
      details:
        "Refinancing your mortgage can offer benefits such as a lower interest rate or shorter loan term. Use this calculator to see if refinancing is right for you.",
      icon: "refinance-cal.svg",
      link: "https://swmc-landingpages.s3.amazonaws.com/mortgage-calculators/refinance-calculator.html",
    },
    {
      id: 3,
      title: "Affordability Calculator",
      details:
        "Input your current expenses and income and see how much home you can afford based on your monthly budget.",
      icon: "affordability.svg",
      link: "https://swmc-landingpages.s3.amazonaws.com/mortgage-calculators/affordability-calculator.html",
    },
    {
      id: 4,
      title: "Rent Vs. Buy Calculator",
      details:
        "Buying a home is a better option for most people in the long run. Use this calculator to compare your current rent with the costs of buying a home.",
      icon: "rent-icon.svg",
      link: "https://swmc-landingpages.s3.amazonaws.com/mortgage-calculators/rent-vs-buy-calculator.html",
    },
  ]);

  // {
  //     id: 3,
  //     title: 'Reverse Mortgage Calculator',
  //     details: "Compare different types of payment options and Reverse Mortgage programs based on the available equity in your home.",
  //     icon: "reverse-icon.svg",
  //     link: "https://revmort.reversesoftonline.com/ReverseMortgage/jsp/extCalculator.jsf?compCde=SUNWST000"
  // },

  return (
    <React.Fragment>
      <Container style={{ marginTop: "100px", marginBottom: "50px" }}>
        <Row className="px-3">
          {calc.map((item) => {
            return (
              <Col
                key={item.id}
                lg={4}
                md={6}
                onClick={() => {
                  setActive(item.id);
                }}
                style={{ cursor: "pointer" }}
                className={
                  Active === item.id
                    ? "shadow text-center p-4 rounded-top calc-box"
                    : "text-center p-4"
                }
              >
                <img
                  src={`./assets/${item.icon}`}
                  alt="Calculator"
                  className="pb-3"
                />
                <h4 className="text-warning-custom fw-bold">
                  {item.title}
                </h4>
                <p className="text-dark" style={{ fontSize: "15px" }}>
                  {item.details}
                </p>
              </Col>
            );
          })}
        </Row>
      </Container>
      <Container className="shadow rounded-bottom calc-container py-3 py-sm-4 py-md-5 px-md-0 px-lg-3 mb-5">
        <Row className="text-center px-sm-0 px-md-5">
          <Col sm={12} md={1} lg={1}>
            <img
              src={`./assets/${calc[Active - 1].icon}`}
              alt={calc[Active - 1].title}
            />
          </Col>
          <Col sm={12} md={11} lg={11}>
            <h2 className="page-title align-items-center pl-3 mb-0">
              {calc[Active - 1].title}
            </h2>
          </Col>
        </Row>

        <Row className="justify-content-center pl-3">
          {Active == "1" ? (
            <Col className="px-0">
              <IframeResizer
                log={false}
                frameBorder="0"
                checkOrigin={false}
                src={calc[0].link}
                heightCalculationMethod="bodyScroll"
                style={{ width: "1px", minWidth: "90%" }}
              />
            </Col>
          ) : null}

          {Active == "2" ? (
            <Col className="px-0">
              <IframeResizer
                log={false}
                frameBorder="0"
                checkOrigin={false}
                src={calc[1].link}
                heightCalculationMethod="bodyScroll"
                style={{ width: "1px", minWidth: "100%" }}
              />
            </Col>
          ) : null}

          {Active == "3" ? (
            <Col className="px-0">
              <IframeResizer
                log={false}
                frameBorder="0"
                scrolling={true}
                checkOrigin={false}
                src={calc[2].link}
                heightCalculationMethod="bodyScroll"
                style={{ width: "1px", minWidth: "100%", minHeight: "472px" }}
              />
            </Col>
          ) : null}

          {Active == "4" ? (
            <Col className="px-0">
              <IframeResizer
                log={false}
                frameBorder="0"
                checkOrigin={false}
                src={calc[3].link}
                heightCalculationMethod="bodyScroll"
                style={{ width: "1px", minWidth: "100%" }}
              />
            </Col>
          ) : null}

          {Active == "5" ? (
            <Col className="px-0">
              <IframeResizer
                log={false}
                frameBorder="0"
                checkOrigin={false}
                src={calc[4].link}
                heightCalculationMethod="bodyScroll"
                style={{ width: "1px", minWidth: "100%" }}
              />
            </Col>
          ) : null}
        </Row>
      </Container>
      <Container>
        <Row className="justify-content-center">
          <Col className="hero-text mt-5 pt-3 text-center" lg={12}>
            <p className="d-inline h3 dark-gray">
              <span className="luck-md">Ready </span>
              <span className="fw-bold pl-2">
                {" "}
                to Connect With Your Local Market Manager?
              </span>
            </p>
          </Col>
          <Col sm={12} md={4} className="text-center mb-5 mt-4">
            <div className="d-grid">
              <a
                className="btn btn-block btn-warning-custom text-white"
                onClick={() => {
                  props.setModalShow(true);
                }}
              >
                Get Started
              </a>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="pt-5">
          <Col className="text-center">
            <Image
              className="triangleImage"
              src={triangles}
              style={{ transform: "scaley(-1) scalex(-1)" }}
            />
          </Col>
        </Row>
      </Container>
     
    </React.Fragment>
  );
}
export default CalcList;
