/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React from "react";

import { Container, Row, Col, Image } from "react-bootstrap";
import MomBaby from "../../images/calculator-mom-baby.png";


function MainContent(props) {
  return (
    <React.Fragment>
      <Container className="pt-5 mt-5">
        <Row>
          <Col md={{ span: 7, offset: 1 }} className="mb-3 px-5">
            <Row className="hero-contents">
              <Col className="hero-text calc-row-title" lg={12}>
                <h1 className="px-2 d-inline page-title">
                  Understanding{" "}
                  <span
                    className="blue-rectangle pb-0"
                    style={{
                      background:
                        "linear-gradient(to top, #98DAF0 45%, transparent 45%)",
                    }}
                  ></span>
                </h1>
                <p className="d-inline h2 page-title">Your Mortgage Payment </p>
                <br />
                <br />
                <br />
                <p>
                  Homebuying is more attainable than a lot of people think!
                  Using a mortgage calculator is a great way to get an idea of
                  what your financing options are and how much home you can
                  afford before you start your home search. Plug in real life
                  scenarios and compare your mortgage options – and when
                  you&#39;re ready your experienced Market Manager will help you
                  get TRU Approved!
                </p>
              </Col>
              <Col className="hero-text pt-5" lg={9} xl={8}>
                <p className="d-inline dark-gray">
                  <span className="luck-md">Ready</span>{" "}
                  <span className="findYourDream pl-2">
                    To Find Your Dream Home?
                  </span>
                </p>
                <br />
                <div className="d-grid">
                <a
                  className="btn btn-block btn-warning-custom mt-4 text-white"
                  onClick={() => {
                    props.setModalShow(true);
                  }}
                >
                  Get Started
                </a>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={4} className="">
            <Image
              fluid
              src={MomBaby}
              alt="Mother with a Baby in front of the laptop"
              className="align-top"
            />
          </Col>
        </Row>
      </Container>
    
    </React.Fragment>
  );
}
export default MainContent;
